import cx from 'classnames';
import {
  graphql,
  Link,
  PageProps,
} from 'gatsby';
import React, {
  useEffect,
  useState,
} from 'react';
import SEO from '../components/seo';
import { usePageContext } from '../contexts/pageContext';
import { ICategory } from '../models/categoryModel';
import categoriesFile from './../../content/data/categories.json';
import styles from './index.module.scss';

const IndexPage: React.FC<
  PageProps<null>
> = ({data}) => {
  const randNum = () =>
    Math.floor(Math.random() * 256);
  const [color, setColor] = useState(
    `rgb(${randNum()},${randNum()},${randNum()})`
  );
  const [isHover, setIsHover] =
    useState(false);

  const changeColor = () => {
    setColor(
      `rgb(${randNum()},${randNum()},${randNum()})`
    );
  };
  const {setIsSideBarShown} = usePageContext()

  useEffect(() => {
    setIsSideBarShown(true)
    return () => {
    };
  }, []);

  useEffect(() => {
    const colorTimer = setInterval(
      changeColor,
      125
    );
    return () => {
      clearInterval(colorTimer);
    };
  }, []);
  // const categories = data.categories.group
  return (
    <div
    className={
      styles.background
    }>
      <SEO title="Welcome | Gordo Labs" />

      <section
        className={cx(
          'md:pl-10 md:border-l-2 h-full md:pr-40 md:flex md:items-center sm:p-3 block py-6 justify-start min-h-screen md:mt-0 mt-16',
          styles.mainContainer
        )}>
        <div
          className={'px-6 max-w-2xl'}>

          <h1 className="text-2xl md:text-3xl font-gordo text-black tracking-wider">
            Hi there, I'm Gordo 🦄
          </h1>
          <h3 className="my-5 text-base md:text-xl font-charisma text-neutral tracking-wide md:w-2/3">
            I am a creative
            multidisciplinary
            professional who enjoys to
            be in the mix:
          </h3>

          <div className="hidden md:flex flex-wrap">
            {categoriesFile.categories.map(
              (cat: ICategory) => {
                return (
                  <Link
                    className="mr-2 mb-2"
                    to={
                      '/category/' +
                      cat.name
                    }>
                    <span
                      key={cat.id}
                      className="font-charisma text-xs px-2 py-1 rounded-md transition"
                      style={{
                        backgroundColor:
                          cat.color
                            ? cat.color
                            : 'white',
                        boxShadow:
                          !cat.color
                            ? '0px, 2px, 7px black'
                            : '0px',
                      }}>
                      {cat.name}
                    </span>
                  </Link>
                );
              }
            )}
          </div>
          <p className="my-5 text-gray-500 font-charisma text-neutral text-sm tracking-wide">
            I'm an architect,
            entrepreneur, product
            designer, frontend, web3, &
            creative developer deeply
            motivated by the application
            of blockchain technology in
            the fields of identity,
            governance and arts.
          </p>
          <p className="my-5 text-gray-500 font-charisma text-neutral text-sm tracking-wide">
            With a deep understanding of
            creative and technical
            processes, I possess a
            unique perspective that
            fuels my passion for
            state-of-the-art projects.
            It's the fusion of artistry
            and practicality that drives
            me and brings me immense
            fulfillment.
          </p>
          {/*           <p className="my-5 text-gray-600 font-charisma text-neutral tracking-wide">
            I have extensive experience
            working with new products,
            leading teams and building
            software for interfaces.
          </p> */}
          <Link to="/base">
            <button
              className="mt-3 text-base md:text-base border-2 rounded-xl font-charisma py-3 px-5 shadow bg-white"
              onMouseEnter={() => {
                setIsHover(!isHover);
              }}
              onMouseLeave={() => {
                setIsHover(!isHover);
              }}
              style={{
                color: isHover
                  ? color
                  : 'black',
                borderColor: isHover
                  ? color
                  : 'rgba(204, 215, 225)',
              }}>
              ☆ ☱ ⛋
            </button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default IndexPage;

// dynamic page query, must occur within each post context
//  $slug is made available by context from createPages call in gatsby-node.js
export const getPostData = graphql`
  query categories {
    categories: allMarkdownRemark {
      group(
        field: frontmatter___categories
      ) {
        fieldValue
      }
    }
  }
`;
